import React, { useContext } from 'react'
import styled from 'styled-components'

import { RiPlayListAddFill } from 'react-icons/ri'
import { StateContext } from '../../../context/StateContext'

const Container = styled.div`
    position: fixed;
    bottom: ${props => props.playlistBottom};
    right: 3rem;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.text};
    transition: all 0.3s ease-in-out;
    visibility: ${props => props.visibility};

    background-color: ${props => props.theme.body};
    box-shadow:  3px 3px 14px #30353c,
                -3px -3px 14px #424751;

    z-index: 8;
`

const FloatingPlaylistButton = () => {
    const { bottom, visibility, modal, setModal, setModalEdit } = useContext(StateContext);

    return (
        <Container playlistBottom={bottom.playlist} visibility={visibility.playlist} onClick={() => {setModal({...modal, playlist: true}); setModalEdit(false)}}>
            <RiPlayListAddFill className='w-6 h-6'/>
            {/* <p className='text-[9px]'>Playlist</p> */}
        </Container>
      )
}

export default FloatingPlaylistButton