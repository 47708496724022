import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { RiCloseFill } from 'react-icons/ri'
import { StateContext } from '../../context/StateContext'

import Button from '../Button'
import { createPlaylist } from '../../redux/apiCalls'

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.background}80;
    backdrop-filter: blur(4px);
    z-index: 10;
`
const Modal = styled.div`
    width: 30%;
    border-radius: 14px;
    background-color: ${props => props.theme.background};
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    input { 
        width: 100%;
        padding: 1rem;
        border: 1px solid ${props => props.theme.body};
        background-color: transparent;
        color: ${props => props.theme.text};
        font-size: 16px;
        border-radius: 8px;

        &:focus {
            outline: none;
        }
        
    }
`

const Playlist = () => {
    const { isFetching, error } = useSelector((state) => state.playlist)
    const { setModal, modalEdit } = useContext(StateContext)
    const [ title, setTitle ] = useState('')

    const dispatch = useDispatch();
    const handleSubmit = (e) => {
        e.preventDefault();
        createPlaylist(dispatch, {title})

        if(!error) {
            setTitle('')
            setModal(false)
        }
    }

    return (
        <Backdrop>
            <Modal>
                <div className='flex justify-between items-center'>
                    {
                        modalEdit ?
                        <h1>Update Playlist</h1>
                        :
                        <h1>Add Playlist</h1>
                    }
                    <Button text={<RiCloseFill className='text-[21px]'/>} onClick={() => setModal({playlist: false, series: false, episodes: false})}/>
                </div>
                <input placeholder='Title' value={title} onChange={(e) => setTitle(e.target.value)}/>
                {
                    isFetching ?
                    <Button text='Updating..' disabled={true}/>
                    :
                    <>
                        {
                            modalEdit ?
                            <Button text='Update' disabled={false} />
                            :
                            <Button text='SAVE' onClick={handleSubmit} disabled={false} />
                        }
                    </>
                }
            </Modal>
            <ToastContainer 
                autoClose={5000} 
                position="top-center"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </Backdrop>
    )
}

export default Playlist