import React from 'react'
import styled from 'styled-components'
import Button from '../Button'

import { RiCloseFill } from 'react-icons/ri'

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.background}80;
    backdrop-filter: blur(4px);
    z-index: 10;
`
const Modal = styled.div`
    width: 30%;
    border-radius: 14px;
    background-color: ${props => props.theme.background};
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    input { 
        width: 100%;
        padding: 1rem;
        border: 1px solid ${props => props.theme.body};
        background-color: transparent;
        color: ${props => props.theme.text};
        font-size: 16px;
        border-radius: 8px;

        &:focus {
            outline: none;
        }
        
    }
`

const Reply = ({ email, name, handleClose }) => {
    return (
        <Backdrop>
            <Modal>
                <div className='flex justify-between items-center'>
                    <h1>Reply to {name}</h1>
                    <Button text={<RiCloseFill className='text-[21px]'/>} onClick={handleClose}/>
                </div>
                <input value={email} disabled/>
                <input placeholder='Subject'/>
                <input placeholder='Message'/>
                <Button text='SEND' />
            </Modal>
        </Backdrop>
    )
}

export default Reply