const useEmbedGenerator = (url) => {
    
    const changeLink = () => {
        let main_link = url.split('=')[1];
        let embed_link = "https://www.youtube.com/embed/" + main_link;
        return embed_link;
    }

    return changeLink();

}

export default useEmbedGenerator;