import { createContext, useState } from 'react'

export const StateContext = createContext();

const StateProvider = ({ children }) => {
    const [ open, setOpen ] = useState(false);
    const [ bottom, setBottom ] = useState({
        playlist: '3rem',
        series: '3rem',
        episodes: '3rem',
        shorts: '3rem',
    });
    const [ visibility, setVisibility ] = useState('hidden')

    const [modal, setModal] = useState({
        playlist: false,
        series: false,
        episode: false,
        shorts: false,
    })
    const [modalEdit, setModalEdit] = useState(false)
    
    const [data, setData] = useState({
        playlist_title: '',
        series_title: '',
        series_cover: '',
        episode_index: '',
        url: '',
    })

    const [del, setDelete] = useState({
        message: false,
        id: '',
        playlist: false,
        series: false,
        episode: false,
        shorts: false,
    })

    return (
        <StateContext.Provider value={{ bottom, setBottom, open, setOpen, visibility, setVisibility, modal, setModal, modalEdit, setModalEdit, data, setData, del, setDelete }}>
            {children}
        </StateContext.Provider>
    )
}

export default StateProvider;