import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ThemeProvider from './context/ThemeContext';
import StateProvider from './context/StateContext';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import { PersistGate } from "redux-persist/integration/react";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading="null" persistor={persistor}>
      <ThemeProvider>
        <StateProvider>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </StateProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

