import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { MdSpaceDashboard, MdPodcasts } from 'react-icons/md'
import { FaHandshake, FaSignOutAlt } from 'react-icons/fa'
import { AiFillSetting } from 'react-icons/ai'
import { useLocation } from 'react-router'
// import Logo from '../../assets/logo/light.png';

const Container = styled.nav`
    width: 20%;
    height: 90vh;
    margin-left: 1rem;
    position: sticky;
    left: 0;
    top: 3rem;
    bottom: 2rem;
    padding: 2rem;
    gap: 2rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    border-radius: 20px;
    background-color: ${props => props.theme.body};
    box-shadow:  20px 20px 60px #30353c,
             -20px -20px 60px #424751;

`
const LogoContainer = styled.div`
    width: 100%;
    display: flex;
`
const PreText = styled.p`
    color: ${props => props.theme.primary};
`
const Lists = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    cursor: pointer;
`
const Items = styled.div`
    color: ${props => props.active ? props.theme.primary : props.theme.text};
`

const Navbar = () => {
    let location = useLocation();

    const getActive = (path) => {
        if(location.pathname === path) {
            return true
        }
    }

    return (
        <Container>
            <LogoContainer className='gap-x-8 items-center'>
                {/* <img src={ Logo } alt='Insight' className='w-20 h-auto mix-blend-luminosity' /> */}
                <div className='flex flex-col gap-y-0 leading-5'>
                    <PreText>ADMIN</PreText>
                    <h1>DASHBOARD</h1>
                </div>
            </LogoContainer> 

            <Lists>
                <Link to = '/'>
                    <Items className='flex gap-x-4 items-center' active={getActive('/')}>
                        <MdSpaceDashboard className='text-[24px]'/>
                        <li>Overview</li>
                    </Items>
                </Link>
                <Link to = '/messages'>
                    <Items className='flex gap-x-4 items-center' active={getActive('/messages')}>
                        <FaHandshake className='text-[24px]'/>
                        <li>Requests</li>
                    </Items>
                </Link>
                <Link to = '/podcasts'>
                    <Items className='flex gap-x-4 items-center' active={getActive('/podcasts')}>
                        <MdPodcasts className='text-[24px]'/>
                        <li>Podcasts</li>
                    </Items>
                </Link>
                <Link to = '/settings'>
                    <Items className='flex gap-x-4 items-center' active={getActive('/settings')}>
                        <AiFillSetting className='text-[24px]'/>
                        <li>Settings</li>
                    </Items>
                </Link>
            </Lists>

            <Lists>
                <div className='flex gap-x-4 items-center' onClick={() => {localStorage.removeItem('access_token'); window.location.reload();}}>
                    <FaSignOutAlt className='text-[24px]'/>
                    <li>Logout</li>
                </div>
            </Lists>

        </Container>
    )
}

export default Navbar