import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { useDispatch } from 'react-redux';

import Global from './assets/Global';
import Navbar from './components/NavbarComponents/Navbar';
import { ThemeContext } from './context/ThemeContext';
import Home from './pages/Home';
import Messages from './pages/Messages';
import Podcasts from './pages/Podcasts';
import { getAllSeries, getAllVideo, getCollabRequests } from './redux/apiCalls';
import Setting from './pages/Setting';
import { useState } from 'react';
import Login from './pages/Login';

const RootContainer = styled.main`
    background-color: ${props => props.theme.body};
`

const Container = styled.article`
    max-width: 1920px;
    margin: 0 auto;
    background-color: ${props => props.theme.body};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`

const App = () => {
    const { theme } = useContext(ThemeContext);
    
    const [user, setUser] = useState(false);
    const isUser = localStorage.getItem('access_token');

    const dispatch = useDispatch();
    useEffect(() => {
        getAllVideo(dispatch);
        getAllSeries(dispatch);
        getCollabRequests(dispatch);
    }, [dispatch])

    useEffect(() => {
        if(isUser !== null) {
            setUser(true);
        }
        else{
            setUser(false);
        }
    }, [setUser, isUser])

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Global />
                <RootContainer>
                    <Container>
                        {
                            user && <Navbar />
                        }
                        <Routes>
                            <Route path = '/' exact element = {user ? <Home /> : <Navigate to='/login' /> } />    
                            <Route path = '/podcasts' element = {user ? <Podcasts /> : <Navigate to='/login' /> } />    
                            <Route path = '/messages' element = {user ? <Messages /> : <Navigate to='/login' /> } />    
                            <Route path = '/settings' element = {user ? <Setting /> : <Navigate to='/login' /> } />   
                            <Route path = '/login' element = {user ? <Navigate to='/' /> : <Login /> } />   
                        </Routes>    
                    </Container>
                </RootContainer>
            </Router>
        </ThemeProvider>
    )
}

export default App