import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import { RiCloseFill } from 'react-icons/ri'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Button from '../Button'
import { StateContext } from '../../context/StateContext'
import { createEpisode } from '../../redux/apiCalls'
import useEmbedGenerator from '../../hooks/useEmbedGenerator';

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.background}80;
    backdrop-filter: blur(4px);
    z-index: 10;
`
const Modal = styled.div`
    width: 30%;
    border-radius: 14px;
    background-color: ${props => props.theme.background};
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    input, select { 
        width: 100%;
        padding: 1rem;
        border: 1px solid ${props => props.theme.body};
        background-color: transparent;
        color: ${props => props.theme.text};
        font-size: 16px;
        border-radius: 8px;

        &:focus {
            outline: none;
        }

        option {
            color: ${props => props.theme.body};
        }
    }
`

const Episode = ({ episodeIndex, selectedSeries, videoUrl }) => {
    const { series } = useSelector((state) => state.series)
    const { isFetching, error } = useSelector((state) => state.episode)
    const { setModal, modalEdit } = useContext(StateContext)

    const [ep, setEp] = useState(episodeIndex);
    const [title, setTitle] = useState('');
    const [selectSeries, setSelectSeries] = useState(selectedSeries);
    const [ytUrl, setYtUrl] = useState(videoUrl);

    const url = useEmbedGenerator(ytUrl);

    const dispatch = useDispatch();
    const handleSubmit = (e) => {
        e.preventDefault();
        
        console.log(url);

        if(selectSeries === '' || selectSeries === undefined) {
            toast.error('Please select a series');
        }
        else {
            createEpisode(dispatch, {ep, title, url}, selectSeries);
            setEp('');
            setYtUrl('');
            
            if (!error) {
                setModal({ playlist: false, episode: false, series: false });
            }
        }
    }

    return (
        <Backdrop>
            <Modal>
                <div className='flex justify-between items-center'>
                    {
                        modalEdit ?
                        <h1>Update Episode</h1>
                        :
                        <h1>Add Episode</h1>
                    }
                    <Button text={<RiCloseFill className='text-[21px]'/>} onClick={() => setModal({playlist: false, series: false, episodes: false})}/>
                </div>
                <input type='number' pattern="[0-9]" placeholder='Episode Index eg.. 1, 2' value={ep} onChange={(e) => setEp(e.target.value)}/>
                <input type='text' placeholder='Episode title' value={title} onChange={(e) => setTitle(e.target.value)}/>
                <select onChange={(e) => setSelectSeries(e.target.value)}>
                    <option disabled selected>Select the series</option>
                    {
                        series.map((item, index) => (
                            <option key={index} value={item._id}>{item.title}</option>
                        ))
                    }
                </select>
                <input type='text' placeholder='Youtube URL' value={ytUrl} onChange={(e) => setYtUrl(e.target.value)} />
                {
                    isFetching ? 
                    <Button text='SAVE' disabled={true} />
                    :
                    <>
                        {
                            modalEdit ?
                            <Button text='UPDATE' onClick={() => {}} />
                            :
                            <Button text='SAVE' onClick={handleSubmit} />
                        }
                    </>
                }
            </Modal>
            <ToastContainer 
                autoClose={5000} 
                position="top-center"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </Backdrop>
    )
}

export default Episode