import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import Button from '../components/Button'
import { loginUser } from '../redux/apiCalls'
import { ToastContainer } from 'react-toastify'

const Container = styled.main`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.body};
`
const Wrapper = styled.div`
    width: 30%;
    padding: 3rem 2rem;

    border-radius: 20px;
    background-color: ${props => props.theme.body};
    box-shadow:  20px 20px 60px #30353c,
            -20px -20px 60px #424751;
`
const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    label {
        font-size: 16px;
        color: ${props => props.theme.disable};
    }
    input {
        background-color: transparent;
        border: none;
        font-size: 16px;

        &:focus {
            outline: none;
        };
    }
`
const InputField = styled.div`
    padding: 0.75rem 1rem;
    display: flex;
    justify-content: space-between;
        
    border-radius: 8px;
    background-color: ${props => props.theme.body};
    box-shadow: inset 20px 20px 60px #30353c,
            inset -20px -20px 60px #424751  
`

const Login = () => {
    const { isFetching, error } = useSelector(state => state.user)
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [visible, setVisible] = useState(false);

    const dispatch = useDispatch();
    const handleSubmit = (e) => {
        e.preventDefault();
        loginUser(dispatch, { email, password }).then(() => {
            if(error === true) {
                return null;
            }
            else {
                window.location.reload();
            }
        });
    }

    return (
        <Container>
            <Wrapper className='flex flex-col gap-y-8'>
                <h1>Login Portal</h1>
                <form className='flex flex-col gap-y-4'>
                    <InputWrapper>
                        <label>Email</label>
                        <InputField>
                            <input type='email' value={email} onChange={(e) => setEmail(e.target.value)}/>
                        </InputField>
                    </InputWrapper>
                    <InputWrapper>
                        <label>Password</label>
                        <InputField>
                            <input type={visible ? 'text' : 'password'} value={password} onChange={(e) => setPassword(e.target.value)}/>
                            <div onClick={(e) => {e.preventDefault(); setVisible(!visible)}}>{visible ? <AiOutlineEyeInvisible className='text-[27px]'/> : <AiOutlineEye className='text-[27px]'/>}</div>
                        </InputField>
                    </InputWrapper>

                    {
                        isFetching ?
                        <Button text='Logging In' disabled={true}/>
                        :
                        <Button text='Login' onClick={handleSubmit}/>
                    }
                </form>
            </Wrapper>

            <ToastContainer 
                autoClose={5000} 
                position="top-center"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </Container>
    )
}

export default Login