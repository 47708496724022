import React, { useContext } from 'react'
import styled from 'styled-components'

import Shorts from '../../../assets/icons/shorts.png';
import { StateContext } from '../../../context/StateContext'

const Container = styled.div`
    position: fixed;
    bottom: ${props => props.bottom};
    right: 3rem;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.text};
    transition: all 0.8s ease-in-out;
    visibility: ${props => props.visibility};

    background-color: ${props => props.theme.body};
    box-shadow:  3px 3px 14px #30353c,
                -3px -3px 14px #424751;

    z-index: 6;
`

const FloatingShortsButton = () => {
    const { bottom, visibility, modal, setModal, setModalEdit } = useContext(StateContext);

    return (
        <Container bottom={bottom.shorts} visibility={visibility}  onClick={() => {setModal({...modal, shorts: true}); setModalEdit(false)}}>
            <img src={Shorts} alt='Shorts' className='w-6 h-6' />
        </Container>
      )
}

export default FloatingShortsButton