export const themes = {
    light: {
        dark: false,
        body: '#F5F5F5',
        background: '#EEEEEE', //darker than body
        text: '#11052C',
        disable: '#7F8487',
        primary: '#C12A37',
        secondary: '#7F1416',
        neutral: '#ffffff',
    },
    dark: {
        dark: true,
        body: '#393E46',
        background: '#222831', //darker than body
        text: '#F5F5F5',
        disable: '#aeaeae',
        primary: '#C12A37',
        secondary: '#7F1416',
        neutral: '#000000',
    }
}