import axiosInstance from "../utils/axios.config";
import { fetchEpisodeFailure, fetchEpisodeStart, fetchEpisodeSuccess } from "./features/episodeSlice";
import { fetchPlaylistStart, fetchPlaylistSuccess, fetchPlaylistFailure } from "./features/playlistSlice";
import { fetchSeriesFailure, fetchSeriesStart, fetchSeriesSuccess } from "./features/seriesSlice";
import { fetchVideoFailure, fetchVideoStart, fetchVideoSuccess } from "./features/videoSlice";

import { toast } from "react-toastify";
import { getFormFailure, getFormStart, getFormSuccess } from "./features/collabSlice";
import { postAuthFailure, postAuthStart, postAuthSuccess } from "./features/authSlice";
import { fetchShortsFailure, fetchShortsStart, fetchShortsSuccess } from "./features/shortsSlice";

export const getAllVideo = async (dispatch) => {
    dispatch(fetchVideoStart());
    try {
        const response = await axiosInstance.get("/video/everything");
        dispatch(fetchVideoSuccess(response.data.everything));
    }
    catch (error) {
        dispatch(fetchVideoFailure());
    }
}

export const getAllSeries = async (dispatch) => {
    dispatch(fetchSeriesStart());
    try {
        const response = await axiosInstance.get("/video/get_all_series");
        dispatch(fetchSeriesSuccess(response.data.series));
    }
    catch (error) {
        dispatch(fetchSeriesFailure());
    }
}

export const getPlaylist = async (dispatch) => {
    dispatch(fetchPlaylistStart());
    try {
        const response = await axiosInstance.get("video/get_playlists");
        dispatch(fetchPlaylistSuccess(response.data.playlists));
    }
    catch (error) {
        dispatch(fetchPlaylistFailure());
    }
}

export const getSeries = async (dispatch, id) => {
    dispatch(fetchSeriesStart());
    try {
        const response = await axiosInstance.get(`video/get_series/${id}`);
        dispatch(fetchSeriesSuccess(response.data.series));
    }
    catch(err) {
        dispatch(fetchSeriesFailure());
    }
}

export const getEpisode = async (dispatch, id) => {
    dispatch(fetchEpisodeStart());
    try {
        const response = await axiosInstance.get(`video/get_episodes/${id}`);
        dispatch(fetchEpisodeSuccess(response.data));
    }
    catch(err) {
        dispatch(fetchEpisodeFailure());
    }
}

export const createPlaylist = async (dispatch, data) => {
    dispatch(fetchPlaylistStart());
    try {
        const response = await axiosInstance.post("video/create_playlist", data);
        dispatch(fetchPlaylistSuccess(response.data));
        toast.success("Playlist created successfully");
        getAllVideo(dispatch);
    }   
    catch (error) {
        dispatch(fetchPlaylistFailure());
        toast.error(error.response.data.error.split(':')[2]);
    }
}

export const createSeries = async (dispatch, data, playlistId) => {
    dispatch(fetchSeriesStart());
    try {
        const response = await axiosInstance.post(`video/create_series/${playlistId}`, data, {
            headers: {
            'Content-Type': 'multipart/form-data'
            }
        });
        dispatch(fetchSeriesSuccess(response.data));
        toast.success("Series created successfully");
        getAllVideo(dispatch);
    }   
    catch (error) {
        dispatch(fetchSeriesFailure());
        toast.error(error.response.data.error.split(':')[2]);
    }
}

export const createEpisode = async (dispatch, data, seriesId) => {
    dispatch(fetchEpisodeStart());
    try {
        const response = await axiosInstance.post(`video/create_episode/${seriesId}`, data);
        dispatch(fetchEpisodeSuccess(response.data));
        toast.success("Episode added successfully");
        getAllVideo(dispatch);
    }
    catch (error) {
        dispatch(fetchEpisodeFailure());
        toast.error(error.response.data.error.split(':')[2]);
    }
}

export const createShorts = async (dispatch, data, seriesId) => {
    dispatch(fetchShortsStart());
    try {
        const response = await axiosInstance.post(`video/add_short/${seriesId}`, data);
        dispatch(fetchShortsSuccess(response.data));
        getAllVideo(dispatch);
    }
    catch (error) {
        dispatch(fetchShortsFailure());
        toast.error(error.response.data.error.split(':')[2]);
    }
}

export const getCollabRequests = async (dispatch) => {
    dispatch(getFormStart());
    try {
        const response = await axiosInstance.get('home/get_colab_reqs');
        dispatch(getFormSuccess(response.data.all_colab_reqs));
    }
    catch (error) {
        dispatch(getFormFailure());
    }
}

export const delMessage = async (dispatch, id) => {
    dispatch(getFormStart());
    try {
        const response = await axiosInstance.delete(`home/del_colab_req/${id}`);
        dispatch(getFormSuccess(response.data.all_colab_reqs));
        getCollabRequests(dispatch);
    }
    catch (error) {
        dispatch(getFormFailure());
        toast.error(error.response.data.error.split(':')[2]);
    }
}

export const delPlaylist = async (dispatch, id) => {
    dispatch(fetchPlaylistStart());
    try {
        const response = await axiosInstance.delete(`video/delete_playlist/${id}`);
        dispatch(fetchPlaylistSuccess(response.data));
        getAllVideo(dispatch);
    }
    catch (error) {
        dispatch(fetchPlaylistFailure());
        console.log(error.response.data);
        toast.error(error.response.data.error);
    }
}
export const delSeries = async (dispatch, id) => {
    dispatch(fetchSeriesStart());
    try {
        const response = await axiosInstance.delete(`video/delete_series/${id}`);
        dispatch(fetchSeriesSuccess(response.data));
        getAllVideo(dispatch);
    }
    catch (error) {
        dispatch(fetchSeriesFailure());
        toast.error(error.response.data.error);
    }
}
export const delEpisode = async (dispatch, id) => {
    dispatch(fetchEpisodeStart());
    try {
        const response = await axiosInstance.delete(`video/delete_episode/${id}`);
        dispatch(fetchEpisodeSuccess(response.data));
        getAllVideo(dispatch);
    }
    catch (error) {
        dispatch(fetchEpisodeFailure());
        toast.error(error.response.data.error.split(':')[2]);
    }
}
export const delShorts = async (dispatch, id) => {
    dispatch(fetchShortsStart());
    try {
        const response = await axiosInstance.delete(`video/delete_short/${id}`);
        dispatch(fetchShortsSuccess(response.data));
        getAllVideo(dispatch);
    }
    catch (error) {
        dispatch(fetchShortsFailure());
        toast.error(error.response.data.error.split(':')[2]);
    }
}

export const loginUser = async (dispatch, data) => {
    dispatch(postAuthStart());
    try{
        const response = await axiosInstance.post('auth/login', data);
        dispatch(postAuthSuccess(response.data));
        localStorage.setItem('access_token', response.data.access_token);
    }
    catch (error) {
        dispatch(postAuthFailure());
        toast.error(error.response.data.error);
    }
}
