import { createSlice } from "@reduxjs/toolkit/dist";

const initialState = {
    collabForm: [
        {
            id: '',
            fullname: '',
            email: '',
            message: '',
            contact: '',
            designation: '',
            organization: '',
        }
    ],
    isFetching: false,
    error: false,
}

const collabSlice = createSlice({
    name: "collabForm",
    initialState,
    reducers: {
        getFormStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getFormSuccess: (state, action) => {
            state.isFetching = false;
            state.collabForm = action.payload;
            state.error = false;
        },
        getFormFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        }
    }
})

export const { getFormStart, getFormSuccess, getFormFailure } = collabSlice.actions;
export default collabSlice.reducer;