import { createSlice } from "@reduxjs/toolkit/dist";

const initialState = {
    playlist: [],
    isFetching: false,
    error: false,
}

const playlistSlice = createSlice({
    name: "playlist",
    initialState,
    reducers: {
        fetchPlaylistStart: (state) => {
            state.isFetching = true;
        },
        fetchPlaylistSuccess: (state, action) => {
            state.isFetching = false;
            state.playlist = action.payload;
        },
        fetchPlaylistFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        }
    }
})

export const { fetchPlaylistStart, fetchPlaylistSuccess, fetchPlaylistFailure } = playlistSlice.actions;
export default playlistSlice.reducer;