import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import SoundEffect from '../../../assets/audio/sao_menu.mp3'

import { RiAddFill } from 'react-icons/ri'
import { StateContext } from '../../../context/StateContext'

const Container = styled.div`
    position: fixed;
    bottom: 3rem;
    right: 3rem;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.text};
    transform: ${props => props.open ? 'rotate(45deg)' : 'rotate(0deg)'};
    transition: 0.3s ease;

    background-color: ${props => props.theme.body};
    box-shadow:  3px 3px 14px #30353c,
                -3px -3px 14px #424751;
    
    z-index: 9;
`

const FloatingButton = () => {
    const { setBottom, open, setOpen, setVisibility } = useContext(StateContext);

    useEffect(() => {
        setOpen(false);
    }, [setOpen]);

    const handleOpen = () => {
        setOpen(!open);
        new Audio(SoundEffect).play();
        
        if(!open) {
            setBottom({
                playlist: '8rem',
                series: '13rem',
                episodes: '18rem',
                shorts: '23rem',
            })
            setVisibility('visible')
        }
        else {
            setBottom({
                playlist: '3rem',
                series: '3rem',
                episodes: '3rem',
                shorts: '3rem',
            })
            setVisibility('hidden')
        }
    }

    return (
        <Container onClick={handleOpen} open={open}>
            <RiAddFill className='w-8 h-8'/>
        </Container>
    )
}

export default FloatingButton