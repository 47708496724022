import React from 'react'
import styled from 'styled-components';
import Stats from '../components/DashboardComponents/Stats';
// import YoutubeStats from '../components/DashboardComponents/YoutubeStats';

const Container = styled.section`
    width: 90%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 4rem;
    row-gap: 6rem;
`

const Home = () => {
  return (
    <Container>
        <Stats />
        {/* <YoutubeStats /> */}
    </Container>
  )
}

export default Home