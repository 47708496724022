import { createSlice } from "@reduxjs/toolkit/dist";

const initialState = {
    episode: [],
    isFetching: false,
    error: false,
}

const episodeSlice = createSlice({
    name: "episode",
    initialState,
    reducers: {
        fetchEpisodeStart: (state) => {
            state.isFetching = true;
        },
        fetchEpisodeSuccess: (state, action) => {
            state.isFetching = false;
            state.episode = action.payload;
        },
        fetchEpisodeFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        }
    }
})

export const { fetchEpisodeStart, fetchEpisodeSuccess, fetchEpisodeFailure } = episodeSlice.actions;
export default episodeSlice.reducer;