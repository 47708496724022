import React, { useContext } from 'react'
import styled from 'styled-components'
import { ThemeContext } from '../../context/ThemeContext'

import { RiChatDeleteFill, RiReplyFill } from 'react-icons/ri'
import { StateContext } from '../../context/StateContext'

const Container = styled.div`
    width: 80%;
    height: 100%;
    display: flex;
    column-gap: 1rem; 
    padding: 1rem 1.5rem;

    border-radius: 14px;
    background-color: ${props => props.theme.body};
    box-shadow:  20px 20px 60px #30353c,
                -20px -20px 60px #424751;
`
const Line = styled.div`
    width: 3px;
    height: 4erm;
    background-color: ${props => props.theme.primary};
`
const Subtitle = styled.p`
    font-size: 16px;
    color: ${props => props.theme.disable};
    font-weight: 300;
`

const Message = ({ name, designation, organization, message, id, handleReply }) => {
    const { theme } = useContext(ThemeContext);
    const { del, setDelete } = useContext(StateContext);

    const handleDelete = (e) => {
        e.preventDefault();
        setDelete({
            ...del,
            message: true,
            id: e.currentTarget.id,
        })
    }
    return (
        <Container>
            <Line />
            
            <form className='w-full flex flex-col gap-2'> {/* MessageContainer */}
                <div className='w-full flex justify-between'> {/* HeadingContainer */}
                    <div className='flex flex-col'> {/* Name and Date */}
                        <h2>{name}</h2>
                        <Subtitle>{designation} {designation !== '' && 'at'} {organization}</Subtitle>
                    </div>
                    <div className='flex gap-x-4'>
                        <RiReplyFill className='text-[21px]' id={id} onClick={handleReply}/>
                        <RiChatDeleteFill className='text-[21px]' style={{color: `${theme.primary}`}} id={id} value={name} onClick={handleDelete} />
                    </div>
                </div>
                <p>{message}</p>
            </form>
        </Container>
    )
}

export default Message