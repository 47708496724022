import { createSlice } from "@reduxjs/toolkit/dist";

const initialState = {
    user: [],
    isFetching: false,
    error: false,
}

const authSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        postAuthStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        postAuthSuccess: (state, action) => {
            state.isFetching = false;
            state.user = action.payload;
            state.error = false;
        },
        postAuthFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        }
    }
})

export const { postAuthStart, postAuthSuccess, postAuthFailure } = authSlice.actions;
export default authSlice.reducer;