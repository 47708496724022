import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import playlistReducer from "./features/playlistSlice";
import seriesReducer from "./features/seriesSlice";
import episodeReucer from "./features/episodeSlice";
import videoReducer from "./features/videoSlice";
import collabReducer from "./features/collabSlice";
import authReducer from "./features/authSlice";
import shortsReducer from './features/shortsSlice';

const persistConfig = {
    key: "root",
    version: 1,
    storage,
};

const rootReducer = combineReducers({ 
    playlist: playlistReducer,
    series: seriesReducer,
    episode: episodeReucer,
    video: videoReducer,
    collabForm: collabReducer,
    user: authReducer,
    shorts: shortsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
});

export let persistor = persistStore(store);