import React, { useContext } from 'react'
import styled from 'styled-components'
import FloatingGroup from '../components/PodcastComponents/FloatingButtonComponents/FloatingGroup'
import Episode from '../components/Modals/Episode'
import Playlist from '../components/Modals/Playlist'
import Series from '../components/Modals/Series'
import Accordian from '../components/PodcastComponents/Accordian'
import { StateContext } from '../context/StateContext'
import Shorts from '../components/Modals/Shorts'

const Container = styled.section`
    width: 90%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 4rem;
    row-gap: 2rem;
`

const Podcasts = () => {
  const { modal, data, modalEdit } = useContext(StateContext);

  return (
    <Container>
      <h1>All Podcasts</h1>
      
      <Accordian />

      {
        modalEdit ?
        <>
          {
            modal.playlist && <Playlist title={data.playlist_title} />
          }
          {
            modal.series && <Series series_title={data.series_title} playlist={data.playlist_title} img={data.series_cover} />
          }
          {
            modal.episode && <Episode episodeIndex={data.episode_index} selectedSeries={data.series_title} videoUrl={data.url}/>
          }
        </>
        :
        <>
          {
            modal.playlist && <Playlist title='' />
          }
          {
            modal.series && <Series series_title='' playlist='' img='' />
          }
          {
            modal.episode && <Episode episodeIndex='' selectedSeries='' videoUrl='' />
          }
          {
            modal.shorts && <Shorts stitle='' selectedSeries='' videoUrl='' />
          }
        </>
      }
      <FloatingGroup />
    </Container>
  )
}

export default Podcasts