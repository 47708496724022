import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { RiArrowRightSLine } from 'react-icons/ri'
import { AiFillDelete } from 'react-icons/ai'

import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/sad_face.json'
import loadingData from '../../assets/lotties/loading.json'

import { StateContext } from '../../context/StateContext'
import DeletePlaylist from '../Modals/DeletePlaylist'
import DeleteEpisode from '../Modals/DeleteEpisode'
import DeleteSeries from '../Modals/DeleteSeries'
import DeleteShorts from '../Modals/DeleteShorts'

const Load = styled.div`
    background-color: ${props => props.theme.body};
    width: 100%;
    height: 100vh;
`

const Container = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
`
const AccordianWrapper = styled.div`
    width: 100%;
    padding: 2rem;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    transition: all 0.5s ease;
    
    background-color: ${props => props.theme.body};
    box-shadow:  20px 20px 60px #30353c,
                -20px -20px 60px #424751;

    &:first-child {
        border-radius: 14px 14px 0 0;
    }
    &:not(:last-child) {
        border-bottom: 1px solid ${props => props.theme.background};
    }
    &:last-child {
        border-radius: 0 0 14px 14px;
    }
`
const SeriesWrapper = styled.div`
    background-color: ${props => props.theme.background};
    border-radius: 14px;

    &:not(:last-child) {
        border-bottom: 1px solid ${props => props.theme.text};
    }
`
const EpisodeWrapper = styled.div`
    background-color: ${props => props.theme.body};
    border-radius: 8px;
`

const Accordian = () => {
    const { del, setDelete } = useContext(StateContext)
    const [playlistOpen, setPlaylistOpen] = useState(-1);

    const { video, isFetching } = useSelector((state) => state.video)

    const handleDeletePlaylist = (e) => {
        e.preventDefault();
        console.log(e.currentTarget.id)
        setDelete({
            ...del,
            message: false,
            playlist: true,
            series: false,
            episode: false,
            shorts: false,
            id: e.currentTarget.id,
        })
    }
    const handleDeleteSeries = (e) => {
        e.preventDefault();
        console.log(e.currentTarget.id)
        setDelete({
            ...del,
            message: false,
            playlist: false,
            series: true,
            episode: false,
            shorts: false,
            id: e.currentTarget.id,
        })
    }
    const handleDeleteEpisode = (e) => {
        e.preventDefault();
        console.log(e.currentTarget.id)
        setDelete({
            ...del,
            message: false,
            playlist: false,
            series: false,
            episode: true,
            shorts: false,
            id: e.currentTarget.id,
        })
    }
    const handleDeleteShort = (e) => {
        e.preventDefault();
        console.log(e.currentTarget.id)
        setDelete({
            ...del,
            message: false,
            playlist: false,
            series: false,
            episode: false,
            shorts: true,
            id: e.currentTarget.id,
        })
    }

    const tooglePlaylist = (index) => {
        setPlaylistOpen(index);
    }

    const loadingOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }

    if (isFetching) {
        return (
            <Load className='fixed top-0 left-0 z-50 flex justify-center items-center'>
                <Lottie
                    options={loadingOptions}
                    height={500}
                    width={500}
                />
            </Load>
        )
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }
    if (video.length < 1) {
        return (
            <div className='w-[80%] h-[40vh] flex flex-col justify-center gap-4 items-center'>
                <Lottie
                    options={defaultOptions}
                    height={200}
                    width={200}
                />
                <p>0 Podcasts</p>
            </div>
        )
    }

    return (
        <Container>
            {
                video.map((item, index) => (
                    <AccordianWrapper key={index}>
                        <div className='flex justify-between'>
                            <h2 className='flex gap-x-4 items-center'>
                                #{index + 1} - {item.title} 
                                <span className='flex gap-x-8'>
                                    {/* <RiEdit2Fill 
                                        className='text-[21px]' 
                                        onClick={() => {
                                            setModal({...modal, playlist: true}); 
                                            setModalEdit(true); 
                                            setData({...data, playlist_title: item.playlist_title})
                                        }}
                                    /> */}
                                    <AiFillDelete 
                                        id={item._id}
                                        className='text-[21px]'
                                        onClick={handleDeletePlaylist}
                                    />
                                </span>
                            </h2>
                            {
                                playlistOpen === index ?
                                <RiArrowRightSLine className='text-[27px] rotate-90 transition-all' onClick={() => setPlaylistOpen(-1)} />
                                :
                                <RiArrowRightSLine className='text-[27px] transition-all' onClick={() => {tooglePlaylist(index)}} />
                            }
                        </div>
                        {
                            playlistOpen === index ?
                            <SeriesWrapper className='w-full h-auto flex flex-col px-8 py-8 gap-y-8 transition-[all 0.5s ease-in-out] translate-y-0'>
                                {
                                    item.series.length > 0 ?
                                    <>
                                        {
                                            item.series.map((series, index) => (
                                                <div className='flex flex-col gap-8' key={index}>
                                                    <div className='flex gap-4'>
                                                        <img src={series.ser.image} alt='cover' className='w-8 h-8 rounded-full object-cover'/>
                                                        <p>{series.ser.title}</p>
                                                        {/* <RiEdit2Fill 
                                                            className='text-[21px]' 
                                                            onClick={() => {
                                                                setModal({...modal, series: true}); 
                                                                setModalEdit(true)
                                                                setData({...data, series_title: series.series_title, series_cover: series.series_cover, playlist_title: item.playlist_title})
                                                            }}
                                                        /> */}
                                                        <AiFillDelete 
                                                            id={series.ser._id}
                                                            className='text-[21px]'
                                                            onClick={handleDeleteSeries}
                                                        />
                                                    </div>
                                                    <div className='flex gap-8 flex-wrap'>
                                                        {
                                                            series.ser.episode.length > 0 ?
                                                            <>
                                                                {
                                                                    series.ser.episode.map((episode, index) => (
                                                                        <EpisodeWrapper className='px-6 py-2 flex gap-x-4 gap-y-2 items-center' key={index}>
                                                                            <p>{episode.epi.ep}</p>
                                                                            {/* <RiEdit2Fill 
                                                                                className='text-[21px]' 
                                                                                onClick={() => {
                                                                                    setModal({...modal, episode: true}); 
                                                                                    setModalEdit(true)
                                                                                    setData({...data, episode_index: episode.episode_title, videoU0rl: episode.url, series_title: series.series_title})
                                                                                }}
                                                                            /> */}
                                                                            <AiFillDelete 
                                                                                id={episode.epi._id}
                                                                                className='text-[21px]'
                                                                                onClick={handleDeleteEpisode}
                                                                            />
                                                                        </EpisodeWrapper>
                                                                    ))
                                                                }
                                                            </>
                                                            :
                                                            <div className='flex flex-col items-center justify-center gap-4'>
                                                                <p className='text-[12px] text-[#aeaeae]'>Nothing to show</p>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className='flex gap-4 flex-wrap'>
                                                        {
                                                            series.ser.shorts.length > 0 &&
                                                            <>
                                                                {
                                                                    series.ser.shorts.map((short, index) => (
                                                                        <EpisodeWrapper className='px-6 py-2 flex gap-x-4 gap-y-2 items-center' key={index}>
                                                                            <p>{short.sho.title}</p>
                                                                            <AiFillDelete
                                                                                id={short.sho._id}
                                                                                className='text-[21px]'
                                                                                onClick={handleDeleteShort}
                                                                            />
                                                                        </EpisodeWrapper>
                                                                    ))
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </>
                                    :
                                    <div className='flex flex-col items-center justify-center gap-4'>
                                        <p>No series added to this playlist.</p>
                                    </div>
                                }
                            </SeriesWrapper>
                            :
                            <SeriesWrapper className='w-full h-[10px] overflow-hidden hidden flex-col px-8 py-8 gap-y-4 transition-[all 0.5s ease-in-out] -translate-y-14'>
                                
                            </SeriesWrapper>
                        }
                    </AccordianWrapper>
                ))
            }
            {
                del.playlist ?
                <DeletePlaylist
                    handleClose={() => setDelete({...del, playlist: false})}
                    id={del.id}
                />
                :
                del.series ? 
                <DeleteSeries
                    handleClose={() => setDelete({...del, series: false})}
                    id={del.id}
                />
                :
                del.episode ?
                <DeleteEpisode
                    handleClose={() => setDelete({...del, episode: false})}
                    id={del.id}
                />
                :
                del.shorts ?
                <DeleteShorts
                    handleClose={() => setDelete({...del, shorts: false})}
                    id={del.id}
                />
                :
                null
            }
        </Container>
    )
}

export default Accordian