import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components'
import AnimatedNumber from 'react-animated-number'

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;


    p{
        color: ${props => props.theme.disable};
        font-weight: 300;
    }
`

const Stats = () => {
    const { collabForm, isFetching } = useSelector(state => state.collabForm);
    const { video } = useSelector(state => state.video);
    const [messageCount, setMessageCount] = useState(0);
    const [playlistCount, setPlaylistCount] = useState(0);

    useEffect(() => {
        if(collabForm.length > 0) {
            setMessageCount(collabForm.length);
        }
        if(video.length > 0) {
            setPlaylistCount(video.length);
        }
    }, [setMessageCount, setPlaylistCount, collabForm.length, video.length]);
    
    return (
        <Container>
            <h1>Overview</h1>
            <div className='w-full flex gap-28 justify-start'>
                <div> {/* Message Counter */}
                    <AnimatedNumber 
                        value={isFetching ? 0 : messageCount}
                        style={{
                            fontSize: '63px',
                            transition: '10s ease-out',
                            transitionProperty: 'background-color, color, opacity'
                        }}
                        duration={900}
                        formatValue={(value) => value.toFixed(0)}
                    />
                    {/* <h1 className='text-[63px] font-normal'>14</h1> */}
                    <p>Messages</p>
                </div>
                <div> {/* Playlist Counter */}
                    <AnimatedNumber 
                        value={playlistCount}
                        style={{
                            fontSize: '63px',
                            transition: '10s ease-out',
                        }}
                        duration={900}
                        formatValue={(value) => value.toFixed(0)}
                    />
                    <p>Playlists</p>
                </div>
            </div>
        </Container>
    )
}

export default Stats