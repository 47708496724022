import React from 'react'
import FloatingButton from './FloatingButton'
import FloatingEpButton from './FloatingEpButton'
import FloatingPlaylistButton from './FloatingPlaylistButton'
import FloatingSeriesButton from './FloatingSeriesButton'
import FloatingShortsButton from './FloatingShortsButton'

const FloatingGroup = () => {
  return (
    <div>
        <FloatingButton />
        <FloatingPlaylistButton />
        <FloatingSeriesButton />
        <FloatingEpButton />
        <FloatingShortsButton />
    </div>
  )
}

export default FloatingGroup