import React, { useContext, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { RiCloseFill } from 'react-icons/ri'
import { BsFillCameraFill } from 'react-icons/bs'
import { MdOutlineRemoveCircle } from 'react-icons/md'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Button from '../Button'
import { StateContext } from '../../context/StateContext'
import { createSeries } from '../../redux/apiCalls'

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.background}80;
    backdrop-filter: blur(4px);
    z-index: 10;
`
const Modal = styled.div`
    width: 30%;
    border-radius: 14px;
    background-color: ${props => props.theme.background};
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    input, select { 
        width: 100%;
        padding: 1rem;
        border: 1px solid ${props => props.theme.body};
        background-color: transparent;
        color: ${props => props.theme.text};
        font-size: 16px;
        border-radius: 8px;

        &:focus {
            outline: none;
        } 

        option {
            color: ${props => props.theme.body};
        }
    }

`
const ImageField = styled.div`
    display: flex;
    width: 100%;
    border-radius: 8px;
    border: 2px dashed ${props => props.theme.body};
    height: 14rem;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    color: ${props => props.theme.body};
    cursor: pointer;
    
    p{
        color: ${props => props.theme.body};
    }
`

const Series = ({ series_title, playlist, img }) => {
    const { video } = useSelector((state) => state.video)
    const { isFetching, error } = useSelector((state) => state.series)
    const { setModal, modalEdit } = useContext(StateContext)
    
    const [image, setImage] = useState('')
    const [selectedImg, setSelectedImg] = useState(img)
    const [selectPlaylist, setSelectPlaylist] = useState(playlist)
    const [title, setTitle] = useState(series_title)

    const [playlists, setPlaylists] = useState([])

    useEffect(() => {
        const getOnlyPlaylists = () => {
            const onlyPlaylists = video.filter(item => item.title)
            setPlaylists(onlyPlaylists)
        }
        getOnlyPlaylists()
    }, [setPlaylists, video]);

    const handleImageChange = (e) => {
        if(e.target.files && e.target.files[0]){
            let img = e.target.files[0];
            setSelectedImg(URL.createObjectURL(img));
            setImage(img)
        }
    }

    const dispatch = useDispatch();
    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('image', image)
        formData.append('title', title)

        if(selectPlaylist===''){
            toast.error('Please select a playlist')
        }
        else {
            createSeries(dispatch, formData, selectPlaylist)
        }

        if(!error) {
            setTitle('')
            setSelectedImg('')
            setSelectPlaylist('')
            setImage('')
            setModal({ playlist: false, episode: false, series: false });
        }
    }

    return (
        <Backdrop>
            <Modal>
                <div className='flex justify-between items-center'>
                    {
                        modalEdit ?
                        <h1>Update Series</h1>
                        :
                        <h1>Add Series</h1>
                    }
                    <Button text={<RiCloseFill className='text-[21px]'/>} onClick={() => setModal({playlist: false, series: false, episodes: false})}/>
                </div>
                <input placeholder='Title' value={title} onChange={(e) => setTitle(e.target.value)}/>
                <select onChange={(e) => setSelectPlaylist(e.target.value)}>
                    <option disabled selected>Select the playlist</option>
                    {
                        playlists.map((item, index) => (
                            <option key={index} value={item._id}>{item.title}</option>
                        ))
                    }
                </select>
                <ImageField>
                    {
                        selectedImg === '' ?
                        <label className='w-full flex flex-col items-center' onChange={handleImageChange}>
                            <BsFillCameraFill className='text-[21px]' />
                            <p>Upload an Image</p>
                            <input type='file' className='hidden' accept='image/*'/>
                        </label>
                        :
                        <div className='relative w-48 h-48'>
                            <img src={selectedImg} alt='series_cover' className='w-full h-full rounded-[8px] object-cover'/>
                            <MdOutlineRemoveCircle className='w-8 h-8 absolute -top-2 -right-2' style={{color: 'red'}} onClick={() => setImage('')}/>
                        </div>
                    } 
                </ImageField>
                {
                    isFetching ?
                    <Button text='SAVE' disabled={true} />
                    :
                    <>
                        {
                            modalEdit ?
                            <Button text='UPDATE' disabled={false} />
                            :
                            <Button text='SAVE' disabled={false} onClick={handleSubmit}/>
                        }
                    </>
                }
            </Modal>
            <ToastContainer 
                autoClose={5000} 
                position="top-center"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </Backdrop>
    )
}

export default Series