import { createSlice } from "@reduxjs/toolkit/dist";

const initialState = {
    video: [],
    isFetching: false,
    error: false,
}

const videoSlice = createSlice({
    name: "video",
    initialState,
    reducers: {
        fetchVideoStart: (state) => {
            state.isFetching = true;
        },
        fetchVideoSuccess: (state, action) => {
            state.isFetching = false;
            state.video = action.payload;
        },
        fetchVideoFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        }
    }
})

export const { fetchVideoStart, fetchVideoSuccess, fetchVideoFailure } = videoSlice.actions;
export default videoSlice.reducer;