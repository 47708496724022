import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Message from '../components/MessageComponents/Message'
import DeleteMessage from '../components/Modals/DeleteMessage'
import Reply from '../components/Modals/Reply'
import { StateContext } from '../context/StateContext'

const data = [
  {
    _id: '19519571',
    name: 'Billie James',
    email: '2000_bil@gmail.com',
    message: 'Hey, I want to collaborate on your podcast!',
    date: '2020-01-01',
  },
  {
    _id: '19519572',
    name: 'Noir Silvana',
    email: 'noirsilvana@outlook.com',
    message: 'Greeting, I want to participate on your podcast and influence millions of your viewers. Please do contact me, I will be very happy to help you!',
    date: '2020-05-24',
  },
  {
    _id: '19519573',
    name: 'Katie Jolie',
    email: 'kattie.jolie@howto.com',
    message: 'I have been watching your podcast for a while now and I am very excited to collaborate on your podcast!. I would like to share my thoughts on the development of youths and to do so, I want to know more about your operations. I hope for your repsonse soon',
    date: '2020-05-24',
  }
]

const Container = styled.section`
    width: 90%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 4rem;
    row-gap: 2rem;
`

const Messages = () => {
  const { collabForm, isFetching } = useSelector(state => state.collabForm)
  const { del, setDelete } = useContext(StateContext);
  const [open, setOpen] = useState(false)
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')

  const replyFunction = (e) => {
    setOpen(true)
    const filteredName = data.find(item => item._id === e.target.id)
    setName(filteredName.name)
    setEmail(filteredName.email)
  }

  return (
    <Container>
      <h1>Collaboration Requests</h1>
      {
        isFetching ? 
        <div>Loading...</div> 
        :
        <>
          {
            collabForm.map((item, index) => (
              <>
                <Message 
                  key={index} 
                  name={item.fullname} 
                  designation={item.designation}
                  organization={item.organization} 
                  message={item.message}
                  id={item._id}
                  handleReply={replyFunction} 
                />
              </>
            ))
          }
        </>
      }

      {
        open && 
        <Reply
          name={name}
          email={email}
          handleClose={() => setOpen(false)}
        />
      }
      {
        del.message &&
        <DeleteMessage
          handleClose={() => setDelete({...del, message: false})}
          id={del.id}
        />
      }
    </Container>
  )
}

export default Messages