import React from 'react'
import styled from 'styled-components';

const Container = styled.article`
    width: 90%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 4rem;
    row-gap: 2rem;
`

const Setting = () => {
    return (
        <Container>
            <h1>Setting</h1>
        </Container>
    )
}

export default Setting